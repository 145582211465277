/**
 * IMPORTS
 */
import {t} from 'i18next';
import {union} from 'lodash';
import initialState from 'src/aggregates/conversations/initialstate';
import {UploadStatuses} from 'src/aggregates/conversations/message/uploads';
import Store from 'src/store';


/**
 * TYPES
 */
import * as Events from 'src/aggregates/conversations/events.d';
import {types as eventTypes} from 'src/aggregates/conversations/events.d';
import {AckTypes} from 'src/aggregates/conversations/message/messages.d';
import {IChatReducer} from 'src/aggregates/conversations/reducer.d';
import {IChatState} from 'src/aggregates/conversations/state.d';
import {IConversation} from 'src/aggregates/conversations/state.d';
import {IMessage as ITimelineMessage} from 'src/aggregates/timelines/state.d';


/**
 * CODE
 */

/**
 * Chat actions map.
 */
const actionsMap: IChatReducer = {

    /**
     * I set chat status on conversation added event.
     *
     * :param state: chat state
     * :param event: conversation added event
     *
     * :returns: chat state
     */
    [eventTypes.CONVERSATION_ADDED]:
        (state: IChatState,
         event: Events.IConversationAdded): IChatState =>
        {
            // get conversation id
            const {_target: id} = event;

            // FIXME: Improve design once it is argued properly
            //
            // initialize shouldExit
            let shouldExit = true;

            // conversation already existed on store: set shouldExit
            if (state.byId[id] !== undefined)
            {
                shouldExit = false;
            }

            // build conversation
            const conversation: IConversation = {
                account: event.account,
                channel: event.channel,
                customer: event.customer,
                end: event.end,
                fetched: false,
                hasAgentAnswer: event.hasAgentAnswer,
                hasFetchError: false,
                id,
                isAutoAnswered: false,
                isFetching: false,
                isSendingTemplate: event.isSendingTemplate,
                lastInteraction: event.lastInteraction,
                messages: {...event.messages},
                remote: event.remote,
                sendingMessages: 0,
                shouldExit,
                start: event.start,
                startTime: event.start * 1000,
                subject: event.subject,
                templateRequired: event.templateRequired,
                templateSent: event.templateSent,
                typedText: '',
                unansweredMessages: event.unansweredMessages,
            };

            // get conversations by id
            const byId = {...state.byId};

            // set conversation on by id
            byId[conversation.id] = conversation;

            // get current
            let {current} = state;

            // get last redirect
            let {lastRedirect} = state;

            // conversation is last removed and no current: set current
            if (id === state.lastRedirect && current === null)
            {
                current = id;
                lastRedirect = null;
            }

            return {
                ...state,
                byId,
                current,
                history: event.history,
                lastRedirect,
                ongoing: event.ongoing,
            };
        },


    /**
     * I update chat state on conversation messages metrics fetched event.
     *
     * :param state: chat state
     * :param event: conversation agents messages metrics fetched event
     *
     * :returns: chat state
     */
    [eventTypes.CONVERSATION_AGENTS_MESSAGES_METRICS_FETCHED]:
        (state: IChatState,
         event: Events.IConversationAgentsMessagesMetricsFetched):
        IChatState =>
        {
            // get agents metrics
            let metrics = {...state.metrics.agents.byId};

            // set messages metrics to each agent
            for (const uid in metrics)
            {
                metrics = {
                    ...metrics,
                    [uid]: {
                        ...metrics[uid],
                        templatesSent: event.metrics[uid] ?? 0,
                    },
                };
            }

            // return updated state
            return {
                ...state,
                metrics: {
                    ...state.metrics,
                    agents: {
                        ...state.metrics.agents,
                        byId: metrics,
                        hasTemplatesError: false,
                        isTemplatesLoading: false,
                    },
                },
            };
        },


    /**
     * I update chat state on conversation messages metrics fetching event.
     *
     * :param state: chat state
     * :param event: conversation agents messages metrics fetching event
     *
     * :returns: chat state
     */
    [eventTypes.CONVERSATION_AGENTS_MESSAGES_METRICS_FETCHING]:
        (state: IChatState,
         event: Events.IConversationAgentsMessagesMetricsFetching):
        IChatState => ({
            ...state,
            metrics: {
                ...state.metrics,
                agents: {
                    ...state.metrics.agents,
                    hasTemplatesError: false,
                    isTemplatesLoading: true,
                },
            },
        }),


    /**
     * I update chat state on conversation messages metrics not fetched event.
     *
     * :param state: chat state
     * :param event: conversation agents messages metrics not fetched event
     *
     * :returns: chat state
     */
    [eventTypes.CONVERSATION_AGENTS_MESSAGES_METRICS_NOT_FETCHED]:
        (state: IChatState,
         event: Events.IConversationAgentsMessagesMetricsNotFetched):
        IChatState => ({
            ...state,
            metrics: {
                ...state.metrics,
                agents: {
                    ...state.metrics.agents,
                    hasTemplatesError: true,
                    isTemplatesLoading: false,
                },
            },
        }),


    /**
     * I update chat state on conversation all pages loaded event.
     *
     * :param state: chat state
     * :param event: conversation all pages loaded event
     *
     * :returns: chat state
     */
    [eventTypes.CONVERSATION_ALL_PAGES_LOADED]:
        (state: IChatState,
         event: Events.IConversationAllPagesLoaded): IChatState => ({
            ...state,
            conversation: {
                ...state.conversation,
                hasLoadingPageError: false,
                hasMorePages: false,
                isLoadingPage: false,
            },
        }),


    /**
     * I set chat status when conversation already exists.
     *
     * :param state: chat state
     * :param event: conversation already exists event
     *
     * :returns: chat state
     */
    [eventTypes.CONVERSATION_ALREADY_EXISTS]:
        (state: IChatState,
         event: Events.IConversationAlreadyExists): IChatState => ({
            ...state,
            conversation: {
                ...state.conversation,
                hasCreationError: false,
                isCreating: false,
            },
            current: event._target,
        }),


    /**
     * I set chat status when a new conversation was created.
     *
     * :param state: chat state
     * :param event: conversation created event
     *
     * :returns: chat state
     */
    [eventTypes.CONVERSATION_CREATED]:
        (state: IChatState, event: Events.IConversationCreated):
        IChatState => ({
            ...state,
            conversation: {
                ...state.conversation,
                hasCreationError: false,
                isCreating: false,
            },
            current: event._target,
        }),


    /**
     * I set chat status when creating a new conversation.
     *
     * :param state: chat state
     * :param event: creating conversation event
     *
     * :returns: chat state
     */
    [eventTypes.CONVERSATION_CREATING]:
        (state: IChatState, event: Events.IConversationCreating):
        IChatState => ({
            ...state,
            conversation: {
                ...state.conversation,
                hasCreationError: false,
                isCreating: true,
            },
        }),


    /**
     * I set chat status on conversation fetched event.
     *
     * :param state: chat state
     * :param event: conversation fetched event
     *
     * :returns: chat state
     */
    [eventTypes.CONVERSATION_FETCHED]:
        (state: IChatState,
         event: Events.IConversationFetched): IChatState =>
        {
            // get conversations by id
            const byId = {...state.byId};

            // get finished conversations by period by id
            const finishedById = {...state.finishedById};

            // conversation does not exist: return unaltered state
            if (byId[event._target] === undefined &&
                finishedById[event._target] === undefined)
            {
                return state;
            }

            // set reduced messages
            const reducedMessages = {};

            // reduce each message
            event.messages.forEach((message: ITimelineMessage) =>
            {
                reducedMessages[message.id] = {
                    ack: message.ack,
                    attachments: message.attachments,
                    body: message.body,
                    caption: message.caption,
                    conversation: event._target,
                    deleted: message.deleted,
                    id: message.id,
                    mode: message.mode ?? null,
                    originalId: message.originalId,
                    outgoing: message.outgoing,
                    reducedBody: message.reducedBody,
                    referral: message.referral,
                    reply: message.reply,
                    timestamp: message.timestamp * 1000,
                    type: message.type,
                };
            });

            // conversation is ongoing: set proper values and messages
            if (byId[event._target] !== undefined)
            {
                // set progress statuses and messages
                byId[event._target].fetched = true;
                byId[event._target].hasFetchError = false;
                byId[event._target].isFetching = false;
                byId[event._target].messages = reducedMessages;
            }

            // conversation is finished: set period values and messages
            else
            {
                // set progress statuses and messages
                finishedById[event._target].fetched = true;
                finishedById[event._target].hasFetchError = false;
                finishedById[event._target].isFetching = false;
                finishedById[event._target].messages = reducedMessages;
            }

            // return updated state
            return {
                ...state,
                byId,
                finishedById,
            };
        },


    /**
     * I set chat status on conversation fetching event.
     *
     * :param state: chat state
     * :param event: conversation fetching event
     *
     * :returns: chat state
     */
    [eventTypes.CONVERSATION_FETCHING]:
        (state: IChatState,
         event: Events.IConversationFetching): IChatState =>
        {
            // get conversations by id
            const byId = {...state.byId};

            // get period conversations by id
            const finishedById = {...state.finishedById};

            // conversation does not exist: return unaltered state
            if (byId[event._target] === undefined &&
                finishedById[event._target] === undefined)
            {
                return state;
            }

            // ongoing conversations are defined: set proper values
            if (byId[event._target] !== undefined)
            {
                // conversation exists: set progress statuses
                byId[event._target].isFetching = true;
                byId[event._target].hasFetchError = false;
            }

            // ongoing conversations are undefined: set period conversations
            else
            {
                finishedById[event._target].isFetching = true;
                finishedById[event._target].hasFetchError = false;
            }

            // return update state
            return {
                ...state,
                byId,
            };
        },


    /**
     * I set chat status on conversation finished event.
     *
     * :param state: chat state
     * :param event: conversation finished event
     *
     * :returns: chat state
     */
    [eventTypes.CONVERSATION_FINISHED]:
        (state: IChatState,
         event: Events.IConversationFinished): IChatState => ({
            ...state,
            conversation: {
                ...state.conversation,
                hasFinishError: false,
                isFinishing: false,
            },
        }),


    /**
     * I set chat status on conversation finishing event.
     *
     * :param state: chat state
     * :param event: conversation finishing event
     *
     * :returns: chat state
     */
    [eventTypes.CONVERSATION_FINISHING]:
        (state: IChatState,
         event: Events.IConversationFinishing): IChatState =>
        {
            // get byId
            const byId = {...state.byId};

            // set should exit as true for each conversation
            for (const conversation of event.conversations)
            {
                byId[conversation] = {
                    ...state.byId[conversation],
                    shouldExit: true,
                };
            }

            // return reduced state
            return {
                ...state,
                byId,
                conversation: {
                    ...state.conversation,
                    hasFinishError: false,
                    isFinishing: true,
                },
            };
        },


    /**
     * I update chat state on conversation focused event.
     *
     * :param state: chat state
     * :param event: conversation focused event
     *
     * :returns: chat state
     */
    [eventTypes.CONVERSATION_FOCUSED]: (
        state: IChatState,
        event: Events.IConversationFocused,
    ): IChatState => ({
        ...state,
        current: event.current,
        currentAccount: event.currentAccount,
        currentChannel: event.currentChannel,
        currentContact: event.currentContact,
    }),


    /**
     * I update chat state on conversation forward started event.
     *
     * :param state: chat state
     * :param event: conversation forward started event
     *
     * :returns: chat state
     */
    [eventTypes.CONVERSATION_FORWARD_STARTED]: (
        state: IChatState,
        event: Events.IConversationForwardStarted,
    ): IChatState => ({
        ...state,
        isForwarding: true,
    }),


    /**
     * I update chat state on conversation forward stopped event.
     *
     * :param state: chat state
     * :param event: conversation forward stopped event
     *
     * :returns: chat state
     */
    [eventTypes.CONVERSATION_FORWARD_STOPPED]: (
        state: IChatState,
        event: Events.IConversationForwardStopped,
    ): IChatState => ({
        ...state,
        forwardingMessages: {},
        isForwarding: false,
    }),


    /**
     * I update chat state on conversation forwarding message added event.
     *
     * :param state: chat state
     * :param event: conversation forwarding message added event
     *
     * :returns: chat state
     */
    [eventTypes.CONVERSATION_FORWARDING_MESSAGE_ADDED]: (
        state: IChatState,
        event: Events.IConversationForwardingMessageAdded,
    ): IChatState => ({
        ...state,
        forwardingMessages: {
            ...state.forwardingMessages,
            [event.message.id]: event.message,
        },
    }),


    /**
     * I update chat state on conversation forwarding message removed event.
     *
     * :param state: chat state
     * :param event: conversation forwarding message removed event
     *
     * :returns: chat state
     */
    [eventTypes.CONVERSATION_FORWARDING_MESSAGE_REMOVED]: (
        state: IChatState,
        event: Events.IConversationForwardingMessageRemoved,
    ): IChatState =>
    {
        // get forwarding messages
        const forwardingMessages = {...state.forwardingMessages};

        // remove target message
        delete forwardingMessages[event.message];

        // return chat state
        return {
            ...state,
            forwardingMessages,
        };
    },


    /**
     * I set chat status on conversation history added event.
     *
     * :param state: chat state
     * :param event: conversation history added event
     *
     * :returns: chat state
     */
    [eventTypes.CONVERSATION_HISTORY_ADDED]:
        (state: IChatState,
         event: Events.IConversationHistoryAdded): IChatState =>
        {
            // get conversations data from event
            const {conversations} = event;

            // initialize finished conversations by id
            let finishedById = {...state.finishedById};

            // push conversations data to finished by id
            conversations.forEach(conversation =>
            {
                finishedById = {
                    ...finishedById,
                    [conversation.id]: {
                        account: conversation.account,
                        agentFinished: conversation.agentFinished ?? null,
                        agentName: conversation.agentName ?? null,
                        attendanceTime: conversation.attendanceTime ?? null,
                        channel: conversation.channel,
                        customer: conversation.customer,
                        end: conversation.end ?? null,
                        fetched: conversation.fetched ?? false,
                        hasAgentAnswer: conversation.hasAgentAnswer ?? null,
                        hasFetchError: conversation.hasFetchError ?? false,
                        id: conversation.id,
                        isAutoAnswered: conversation.isAutoAnswered ?? false,
                        isFetching: conversation.isFetching ?? false,
                        isSendingTemplate: conversation.isSendingTemplate
                                           ?? false,
                        lastInteraction: conversation.lastInteraction ?? null,
                        messages: conversation.messages ?? {},
                        remote: conversation.remote,
                        sendingMessages: conversation.sendingMessages ?? 0,
                        shouldExit: conversation.shouldExit ?? true,
                        start: conversation.start,
                        startTime: (conversation.startTime ??
                                    conversation.start * 1000) || 0,
                        status: conversation.status,
                        subject: conversation.subject,
                        templateRequired: conversation.templateRequired
                                          ?? false,
                        templateSent: conversation.templateSent ?? null,
                        typedText: conversation.typedText ?? '',
                        unansweredMessages: conversation.unansweredMessages,
                        waitTime: conversation.waitTime ?? null,
                    },
                };
            });

            // return updated state
            return {
                ...state,
                finishedById,
            };
        },


    /**
     * I set chat status on conversation not fetched event.
     *
     * :param state: chat state
     * :param event: conversation not fetched event
     *
     * :returns: chat state
     */
    [eventTypes.CONVERSATION_NOT_FETCHED]:
        (state: IChatState,
         event: Events.IConversationNotFetched): IChatState =>
        {
            // get by id
            const byId = {...state.byId};

            // conversation does not exist: return unaltered state
            if (byId[event._target] === undefined)
            {
                return state;
            }

            // conversation exists: set progress statuses
            byId[event._target].isFetching = false;
            byId[event._target].hasFetchError = true;

            // return updated state
            return {
                ...state,
                byId,
            };
        },


    /**
     * I update chat state on an exited conversation.
     *
     * :param state: chat state
     * :param event: conversation exited event
     *
     * :returns: chat state
     */
    [eventTypes.CONVERSATION_EXITED]:
        (state: IChatState, event: Events.IConversationExited): IChatState =>
        {
            // get conversation id and status
            const {_target: id} = event;
            const {status} = event;

            // get conversations
            const byId = {...state.byId};

            // get conversation
            const conversation = byId[id];

            // conversation does not exist: return unaltered state
            if (conversation === undefined)
            {
                return state;
            }

            // conversation exists: update it
            byId[id] = {
                ...conversation,
                status,
            };

            // return conversation state
            return {
                ...state,
                byId,
            };
        },


    /**
     * I set chat status when conversations have been loaded.
     *
     * :param state: chat state
     * :param event: conversation loaded event
     *
     * :returns: chat state
     */
    [eventTypes.CONVERSATION_LOADED]:
        (state: IChatState,
         event: Events.IConversationLoaded): IChatState =>
        {
            // initialize conversations registry
            const byId = {};

            // reduce conversations
            for (const conversation of event.conversations)
            {
                // get conversation from store
                const stored = state.byId[conversation.id];

                // register conversation
                byId[conversation.id] = {
                    ...stored,
                    account: conversation.account.id,
                    channel: conversation.account.channel,
                    customer: conversation.customer,
                    end: conversation.end,
                    fetched: false,
                    hasAgentAnswer: conversation.hasAgentAnswer,
                    hasFetchError: false,
                    id: conversation.id,
                    isFetching: false,
                    lastInteraction: conversation.lastInteraction,
                    messages: stored?.messages ?? {},
                    remote: conversation.contact.uid,
                    sendingMessages: 0,
                    shouldExit: true,
                    start: conversation.start,
                    startTime: conversation.start * 1000,
                    subject: conversation.subject,
                    typedText: '',
                    unansweredMessages: conversation.unansweredMessages,
                };
            }

            // return updated state
            return {
                ...state,
                byId,
                conversation: {
                    ...state.conversation,
                    hasLoadError: false,
                    isLoading: false,
                },
                history: [],
                ongoing: event.ongoing,
                page: 1,
            };
        },

    /**
     * I set chat status on all conversations loaded by period.
     *
     * :param state: chat state
     * :param event: conversation loaded all by period event
     *
     * :returns: chat state
     */
    [eventTypes.CONVERSATION_LOADED_ALL_BY_PERIOD]:
        (state: IChatState,
         event: Events.IConversationLoadedAllByPeriod): IChatState =>
        {
            // get agent history
            const byAgent = {...state.byAgent};

            // agent history does not exist: create one
            if (byAgent[event.agent] === undefined)
            {
                byAgent[event.agent] = {
                    conversations: [],
                    hasMore: false,
                    since: event.since,
                };
            }

            // agent history already exists: update it
            else
            {
                byAgent[event.agent].hasMore = false;
                byAgent[event.agent].since = event.since;
            }

            // return updated state
            return {
                ...state,
                byAgent,
                conversation: {
                    ...state.conversation,
                    hasLoadByPeriodError: false,
                    isLoadingByPeriod: false,
                },
            };
        },


    /**
     * I set chat status when conversations have been loaded by customer.
     *
     * :param state: chat state
     * :param event: conversation loaded by customer event
     *
     * :returns: chat state
     */
    [eventTypes.CONVERSATION_LOADED_BY_CUSTOMER]:
        (state: IChatState,
         event: Events.IConversationLoadedByCustomer): IChatState =>
        {
            // get customer history
            const byCustomer = {...state.byCustomer};

            // update customer history
            byCustomer[event.customer] = {
                ...byCustomer[event.customer],
                finished: event.newIds,
            };

            // return updated state
            return {
                ...state,
                byCustomer,
                conversation: {
                    ...state.conversation,
                    isLoadingByCustomer: false,
                },
            };
        },


    /**
     * I set chat status when conversations have been loaded by period.
     *
     * :param state: chat state
     * :param event: conversation loaded by period event
     *
     * :returns: chat state
     */
    [eventTypes.CONVERSATION_LOADED_BY_PERIOD]:
        (state: IChatState,
         event: Events.IConversationLoadedByPeriod): IChatState =>
        {
            // get agent history
            const byAgent = {...state.byAgent};

            // update agent history
            byAgent[event.agent] = {
                conversations: union(
                    byAgent[event.agent]?.conversations ?? [],
                    event.newIds,
                ),
                hasMore: true,
                since: event.since,
            };

            // return updated state
            return {
                ...state,
                byAgent,
                conversation: {
                    ...state.conversation,
                    hasLoadByPeriodError: false,
                    isLoadingByPeriod: false,
                },
            };
        },


    /**
     * I save ongoing conversations by customer on store.
     *
     * :param state: chat state
     * :param event: event attributes
     *
     * :returns: updated state
     */
    [eventTypes.CONVERSATION_LOADED_ONGOING_BY_CUSTOMER]:
        (state: IChatState,
         event: Events.IConversationLoadedOngoingByCustomer): IChatState =>
        {
            // get customer data from store
            const byCustomer = {...state.byCustomer};

            // push ongoing conversations ids to customer
            byCustomer[event.customer] = {
                ...byCustomer[event.customer],
                ongoing: event.ids,
            };

            // return updated state
            return {
                ...state,
                byCustomer,
                conversation: {
                    ...state.conversation,
                    hasLoadByCustomerError: false,
                    isLoadingByCustomer: false,
                },
            };
        },


    /**
     * I set chat status when loading conversations.
     *
     * :param state: chat state
     * :param event: conversation loading event
     *
     * :returns: chat state
     */
    [eventTypes.CONVERSATION_LOADING]:
        (state: IChatState,
         event: Events.IConversationLoading): IChatState => ({
            ...state,
            conversation: {
                ...state.conversation,
                hasLoadError: false,
                isLoading: true,
            },
        }),


    /**
     * I set chat status on conversations loading by customer.
     *
     * :param state: chat state
     * :param event: conversation loading by customer event
     *
     * :returns: chat state
     */
    [eventTypes.CONVERSATION_LOADING_BY_CUSTOMER]:
        (state: IChatState,
         event: Events.IConversationLoadingByCustomer): IChatState => ({
            ...state,
            conversation: {
                ...state.conversation,
                hasLoadByCustomerError: false,
                isLoadingByCustomer: true,
            },
        }),


    /**
     * I set chat status on conversations loading by period.
     *
     * :param state: chat state
     * :param event: conversation loading by period event
     *
     * :returns: chat state
     */
    [eventTypes.CONVERSATION_LOADING_BY_PERIOD]:
        (state: IChatState,
         event: Events.IConversationLoadingByPeriod): IChatState => ({
            ...state,
            conversation: {
                ...state.conversation,
                hasLoadByPeriodError: false,
                isLoadingByPeriod: true,
            },
        }),


    /**
     * I flag that state is loading ongoing conversations by customer.
     *
     * :param state: chat state
     * :param event: event attributes
     *
     * :returns: chat state
     */
    [eventTypes.CONVERSATION_LOADING_ONGOING_BY_CUSTOMER]:
    (state: IChatState,
     event: Events.IConversationLoadingOngoingByCustomer): IChatState => ({
        ...state,
        conversation: {
            ...state.conversation,
            hasLoadByCustomerError: false,
            isLoadingByCustomer: true,
        },
    }),


    /**
     * I set conversation status on successful heartbeat.
     *
     * :param state: conversation state
     * :param event: conversation heartbeat succeeded event
     *
     * :returns: conversation state
     */
    [eventTypes.CONVERSATION_HEARTBEAT_SUCCEEDED]:
        (state: IChatState,
         event: Events.IConversationHeartbeatSucceeded): IChatState => ({
            ...state,
            onQueue: event.conversationsOnQueue,
        }),


    /**
     * I update chat state on a feedback added.
     *
     * :param state: chat state
     * :param event: feedback added event
     *
     * :returns: chat state
     */
    [eventTypes.CONVERSATION_FEEDBACK_ADDED]: (
        state: IChatState,
        event: Events.IConversationFeedbackAdded,
    ): IChatState =>
    {
        // get state feedbacks
        const feedbacks = [...state.feedbacks];

        // add feedback
        feedbacks.push({
            closable: event.closable,
            duration: event.duration,
            message: event.message,
            title: event.title,
            type: event.toastType,
        });

        // return new state with updated feedbacks
        return ({
            ...state,
            feedbacks,
        });
    },


    /**
     * I update chat state on a feedback removed.
     *
     * :param state: chat state
     * :param event: feedback removed event
     *
     * :returns: chat state
     */
    [eventTypes.CONVERSATION_FEEDBACK_REMOVED]: (
        state: IChatState,
        event: Events.IConversationFeedbackRemoved,
    ): IChatState =>
    {
        // get state feedbacks
        const feedbacks = [...state.feedbacks];

        // remove feedback
        feedbacks.splice(event.index, 1);

        // return state with updated feedbacks
        return ({
            ...state,
            feedbacks,
        });
    },

    /**
     * I update chat state on metrics fetched event.
     *
     * :param state: chat state
     * :param event: metrics fetched event
     *
     * :returns: chat state
     */
    [eventTypes.CONVERSATION_METRICS_FETCHED]: (
        state: IChatState,
        event: Events.IConversationMetricsFetched,
    ): IChatState => ({
        ...state,
        metrics: {
            ...state.metrics,
            agents: {
                ...state.metrics.agents,
                byId: event.metrics.byAgent,
            },
            answered: event.metrics.answered,
            chatbot: event.metrics.chatbot,
            contacts: event.metrics.contacts,
            finished: event.metrics.finished,
            hasError: false,
            incoming: event.metrics.incoming,
            isLoading: false,
            lastEnd: state.metrics.endDate,
            lastStart: state.metrics.startDate,
            lost: event.metrics.lost,
            ongoing: event.metrics.ongoing,
            outgoing: event.metrics.outgoing,
        },
    }),


    /**
     * I update chat state on metrics fetching event.
     *
     * :param state: chat state
     * :param event: metrics fetching event
     *
     * :returns: chat state
     */
    [eventTypes.CONVERSATION_METRICS_FETCHING]: (
        state: IChatState,
        event: Events.IConversationMetricsFetching,
    ): IChatState => ({
        ...state,
        metrics: {
            ...state.metrics,
            hasError: false,
            isLoading: true,
        },
    }),


    /**
     * I update chat state on metrics not fetched event.
     *
     * :param state: chat state
     * :param event: metrics not fetched event
     *
     * :returns: chat state
     */
    [eventTypes.CONVERSATION_METRICS_NOT_FETCHED]: (
        state: IChatState,
        event: Events.IConversationMetricsNotFetched,
    ): IChatState => ({
        ...state,
        metrics: {
            ...state.metrics,
            hasError: true,
            isLoading: false,
        },
    }),


    /**
     * I update chat state on metrics period set event.
     *
     * :param state: chat state
     * :param event: metrics period set event
     *
     * :returns: chat state
     */
    [eventTypes.CONVERSATION_METRICS_PERIOD_SET]: (
        state: IChatState,
        event: Events.IConversationMetricsPeriodSet,
    ): IChatState =>
    {
        // get event props
        const {endDate, startDate} = event;

        // return updated state
        return {
            ...state,
            metrics: {...state.metrics, endDate, startDate},
        };
    },


    /**
     * I set conversation status when creating a conversation fails.
     *
     * :param state: chat state
     * :param event: conversation not created event
     *
     * :returns: chat state
     */
    [eventTypes.CONVERSATION_NOT_CREATED]:
        (state: IChatState, event: Events.IConversationNotCreated):
        IChatState => ({
            ...state,
            conversation: {
                ...state.conversation,
                hasCreationError: true,
                isCreating: false,
            },
            creationFailureReason: 'unknownReason',
            errorMessage: t('Houve um problema ao iniciar a conversa, por ' +
                            'favor, tente novamente'),
        }),


    /**
     * I set chat status on conversation not finished event.
     *
     * :param state: chat state
     * :param event: conversation not finished event
     *
     * :returns: chat state
     */
    [eventTypes.CONVERSATION_NOT_FINISHED]:
        (state: IChatState,
         event: Events.IConversationNotFinished): IChatState => ({
            ...state,
            conversation: {
                ...state.conversation,
                hasFinishError: true,
                isFinishing: false,
            },
        }),


    /**
     * I set chat status when conversations have not been loaded.
     *
     * :param state: chat state
     * :param event: conversation not loaded event
     *
     * :returns: chat state
     */
    [eventTypes.CONVERSATION_NOT_LOADED]:
        (state: IChatState,
         event: Events.IConversationNotLoaded): IChatState => ({
            ...state,
            byId: {},
            conversation: {
                ...state.conversation,
                hasLoadError: true,
                isLoading: false,
            },
        }),


    /**
     * I set chat status when conversations have not been loaded by period.
     *
     * :param state: chat state
     * :param event: conversation not loaded by period event
     *
     * :returns: chat state
     */
    [eventTypes.CONVERSATION_NOT_LOADED_BY_CUSTOMER]:
        (state: IChatState,
         event: Events.IConversationNotLoadedByCustomer): IChatState => ({
            ...state,
            conversation: {
                ...state.conversation,
                hasLoadByCustomerError: true,
                isLoadingByCustomer: false,
            },
        }),


    /**
     * I set chat status when conversations have not been loaded by period.
     *
     * :param state: chat state
     * :param event: conversation not loaded by period event
     *
     * :returns: chat state
     */
    [eventTypes.CONVERSATION_NOT_LOADED_BY_PERIOD]:
        (state: IChatState,
         event: Events.IConversationNotLoadedByPeriod): IChatState => ({
            ...state,
            conversation: {
                ...state.conversation,
                hasLoadByPeriodError: true,
                isLoadingByPeriod: false,
            },
        }),


    /**
     * I flag state could not load ongoing conversations by customer.
     *
     * :param state: chat state
     * :param event: event attributes
     *
     * :returns: chat state
     */
    [eventTypes.CONVERSATION_NOT_LOADED_ONGOING_BY_CUSTOMER]:
    (state: IChatState,
     event: Events.IConversationNotLoadedOngoingByCustomer): IChatState => ({
        ...state,
        conversation: {
            ...state.conversation,
            hasLoadByCustomerError: true,
            isLoadingByCustomer: false,
        },
    }),


    /**
     * I update chat state on conversation page loaded event.
     *
     * :param state: chat state
     * :param event: conversation page loaded event
     *
     * :returns: chat state
     */
    [eventTypes.CONVERSATION_PAGE_LOADED]:
        (state: IChatState,
         event: Events.IConversationPageLoaded): IChatState => ({
            ...state,
            conversation: {
                ...state.conversation,
                hasLoadingPageError: false,
                isLoadingPage: false,
            },
            history: event.history.filter(
                customer => state.ongoing.includes(customer) === false,
            ),
            page: event.page,
        }),


    /**
     * I update chat state on conversation page loading event.
     *
     * :param state: chat state
     * :param event: conversation page loading event
     *
     * :returns: chat state
     */
    [eventTypes.CONVERSATION_PAGE_LOADING]:
        (state: IChatState,
         event: Events.IConversationPageLoading): IChatState => ({
            ...state,
            conversation: {
                ...state.conversation,
                hasLoadingPageError: false,
                isLoadingPage: true,
            },
        }),


    /**
     * I update chat state on conversation page not loaded event.
     *
     * :param state: chat state
     * :param event: conversation page not loaded event
     *
     * :returns: chat state
     */
    [eventTypes.CONVERSATION_PAGE_NOT_LOADED]:
        (state: IChatState,
         event: Events.IConversationPageNotLoaded): IChatState => ({
            ...state,
            conversation: {
                ...state.conversation,
                hasLoadingPageError: true,
                isLoadingPage: false,
            },
        }),


    /**
     * I update chat state on conversation removed.
     *
     * :param state: chat state
     * :param event: conversation removed event
     *
     * :returns: chat state
     */
    [eventTypes.CONVERSATION_REMOVED]:
        (state: IChatState, event: Events.IConversationRemoved): IChatState =>
        {
            // get removed conversation id
            const {_target: id} = event;

            // get conversations
            const conversations = {...state.byId};

            // conversation does not exist: return unaltered state
            if (conversations[id] === undefined)
            {
                return state;
            }

            // remove conversation
            delete conversations[id];

            // get last redirect conversation
            let {lastRedirect} = state;

            // get current conversation
            let {current} = state;

            // removed conversation is the current: set as last redirect
            if (current === id)
            {
                current = null;
                lastRedirect = id;
            }

            // return conversation state
            return {
                ...state,
                byId: conversations,
                current,
                history: event.history,
                lastRedirect,
                ongoing: event.ongoing,
            };
        },


    /**
     * I update chat state on session closed.
     *
     * :param state: chat state
     * :param event: conversation session closed event
     *
     * :returns: chat state
     */
    [eventTypes.CONVERSATION_SESSION_CLOSED]:
        (state: IChatState, event: Events.IConversationSessionClosed):
        IChatState => ({
            ...state,
            connected: false,
        }),


    /**
     * I update chat state on forced session end.
     *
     * :param state: chat state
     * :param event: conversation session forcibly ended event
     *
     * :returns: chat state
     */
    [eventTypes.CONVERSATION_SESSION_FORCIBLY_ENDED]:
        (state: IChatState,
         event: Events.IConversationSessionForciblyEnded): IChatState => ({
            ...state,
            isSessionForciblyEnded: true,
        }),


    /**
     * I update chat state on session not opened
     *
     * :param state: chat state
     * :param event: conversation session not opened event
     *
     * :returns: chat state
     */
    [eventTypes.CONVERSATION_SESSION_NOT_OPENED]:
        (state: IChatState, event: Events.IConversationSessionNotOpened):
        IChatState => ({
            ...state,
            connected: false,
            connecting: Number(new Date()),
        }),


    /**
     * I update chat state on session opened.
     *
     * :param state: chat state
     * :param event: conversation session opened event
     *
     * :returns: chat state
     */
    [eventTypes.CONVERSATION_SESSION_OPENED]:
        (state: IChatState, event: Events.IConversationSessionOpened):
        IChatState => ({
            ...state,
            connected: true,
            connecting: 0,
            user: {
                ...state.user,
                push: event.push,
                session: event.session,
            },
        }),


    /**
     * I update chat state on start.
     *
     * :param state: chat state
     * :param event: conversation started event
     *
     * :returns: chat state
     */
    [eventTypes.CONVERSATION_STARTED]:
        (state: IChatState, event: Events.IConversationStarted):
        IChatState => ({
            ...state,
            started: true,
        }),


    /**
     * I update chat state on stop.
     *
     * :param state: chat state
     * :param event: conversation stopped event
     *
     * :returns: chat state
     */
    [eventTypes.CONVERSATION_STOPPED]:
        (state: IChatState, event: Events.IConversationStopped):
        IChatState => ({
            ...state,
            stopped: true,
        }),


    /**
     * I update chat state on template not sent.
     *
     * :param state: chat state
     * :param event: conversation template not sent event
     *
     * :returns: chat state
     */
    [eventTypes.CONVERSATION_TEMPLATE_NOT_SENT]:
        (state: IChatState, event: Events.IConversationTemplateNotSent):
        IChatState =>
        {
            // get conversation id
            const {_target: id} = event;

            // get message props
            const {originalId} = event;

            // get conversations
            const byId = {...state.byId};

            // get conversation
            const conversation = byId[id];

            // conversation does not exist: return unaltered state
            if (conversation === undefined)
            {
                return state;
            }

            // conversation exists: get conversation messages
            const messages = {...conversation.messages};

            // decrease sending messages
            conversation.sendingMessages -= 1;

            // get message
            let message = messages[originalId] ?? undefined;

            // message exists: update message
            if (message !== undefined)
            {
                // update message ack to not transmitted
                message = {
                    ...message,
                    ack: AckTypes.NOT_TRANSMITTED,
                };

                messages[originalId] = message;

                // update conversation messages
                byId[id] = {
                    ...conversation,
                    isSendingTemplate: false,
                    messages,
                };
            }

            // return updated conversation state
            return {
                ...state,
                byId,
                conversation: {
                    ...state.conversation,
                    hasTemplateSendError: true,
                },
            };
        },


    /**
     * I update chat state on template sending.
     *
     * :param state: chat state
     * :param event: conversation template sending event
     *
     * :returns: chat state
     */
    [eventTypes.CONVERSATION_TEMPLATE_SENDING]:
        (state: IChatState, event: Events.IConversationTemplateSending):
        IChatState =>
        {
            // get conversation id
            const {_target: id} = event;

            // get conversations
            const byId = {...state.byId};

            // get chat by id
            const chat = byId[id];

            // chat does not exist: return unaltered state
            if (chat === undefined)
            {
                return state;
            }

            // set message
            const message = {
                ...event.payload,
                ack: AckTypes.TRANSMITTING,
            };

            // chat exists: get messages
            const messages = {...chat.messages};

            // add new message
            messages[message.originalId] = message;

            // get sending messages
            const sendingMessages = byId[id].sendingMessages;

            // set conversation
            byId[id] = {
                ...chat,
                isSendingTemplate: true,
                lastInteraction: Date.now() / 1000,
                messages,
                sendingMessages: sendingMessages + 1,
                unansweredMessages: 0,
            };

            // return chat state
            return {
                ...state,
                byId,
                conversation: {
                    ...state.conversation,
                    hasTemplateSendError: false,
                },
            };
        },


    /**
     * I update chat state on template sent.
     *
     * :param state: chat state
     * :param event: conversation template sent event
     *
     * :returns: chat state
     */
    [eventTypes.CONVERSATION_TEMPLATE_SENT]:
        (state: IChatState, event: Events.IConversationTemplateSent):
        IChatState =>
        {
            // get conversation id
            const {_target: id} = event;

            // get old originalId
            const {oldOriginalId} = event;

            // get message payload returned from conversis
            const {payload} = event;

            // get conversations
            const byId = {...state.byId};

            // get conversation
            const conversation = byId[id];

            // conversation does not exist: return unaltered state
            if (conversation === undefined)
            {
                return state;
            }

            // conversation exists: get conversation messages
            const messages = {...conversation.messages};

            // decrease sending messages
            conversation.sendingMessages -= 1;

            // get message
            let message = messages[oldOriginalId];

            // message exists: update message
            if (message !== undefined)
            {
                // remove old message indexed by old original id
                delete messages[oldOriginalId];

                // update message ack and set message id and body
                message = payload;

                // index message by message id
                messages[payload.id] = message;

                // update conversation
                byId[id] = {
                    ...conversation,
                    hasAgentAnswer: true,
                    isSendingTemplate: false,
                    messages,
                    templateSent: message.timestamp,
                };
            }

            // return chat state
            return {
                ...state,
                byId,
                conversation: {
                    ...state.conversation,
                    hasTemplateSendError: false,
                },
            };
        },


    /**
     * I set conversation status when conversation is with another agent.
     *
     * :param state: chat state
     * :param event: conversation with anoter agent event
     *
     * :returns: chat state
     */
    [eventTypes.CONVERSATION_WITH_ANOTHER_AGENT]:
        (state: IChatState,
         event: Events.IConversationWithAnotherAgent): IChatState => ({
            ...state,
            conversation: {
                ...state.conversation,
                hasCreationError: true,
                isCreating: false,
            },
            creationFailureReason: 'conversationWithAnotherAgent',
            errorMessage: t(
                '{{ name }} está conversando com este cliente no momento',
                {replace: {name: event.agent}},
            ),
        }),


    /**
     * I set conversation status when conversation is with another app.
     *
     * :param state: chat state
     * :param event: conversation with anoter app event
     *
     * :returns: chat state
     */
    [eventTypes.CONVERSATION_WITH_ANOTHER_APP]:
        (state: IChatState,
         event: Events.IConversationWithAnotherApp): IChatState => ({
            ...state,
            conversation: {
                ...state.conversation,
                hasCreationError: true,
                isCreating: false,
            },
            creationFailureReason: 'conversationWithAnotherApp',
            errorMessage:
                t('Cliente já está em contato com a empresa neste momento'),
        }),


    /**
     * I set isAutoAnswered when customer was already auto answered.
     *
     * :param state: chat state
     * :param event: conversation already exists event
     *
     * :returns: chat state
     */
    [eventTypes.CONVERSATION_AUTOANSWERED]:
        (state: IChatState,
         event: Events.IConversationAutoAnswered): IChatState => ({
            ...state,
            byId: {
                ...state.byId,
                [event._target]: {
                    ...state.byId[event._target],
                    isAutoAnswered: true,
                },
            },
        }),


    /**
     * I update chat state on reset.
     *
     * :param state: chat state
     * :param event: error reset event
     *
     * :returns: chat state
     */
    [eventTypes.CONVERSATION_ERROR_RESET]:
        (state: IChatState,
         event: Events.IConversationErrorReset): IChatState => ({
            ...state,
            conversation: {
                ...state.conversation,
                hasCreationError: false,
                hasError: false,
                hasTemplateSendError: false,
                isCreating: false,
                isFetching: false,
            },
            creationFailureReason: '',
            errorMessage: '',
        }),


    /**
     * I update chat state on message ack received.
     *
     * :param state: chat state
     * :param event: message ack received event
     *
     * :returns: chat state
     */
    [eventTypes.CONVERSATION_MESSAGE_ACK_RECEIVED]:
        (state: IChatState,
         event: Events.IConversationMessageAckReceived): IChatState =>
        {
            // get conversations
            const byId = {...state.byId};

            // get conversation
            const conversation = byId[event._target];

            // conversation exists: get conversation messages
            const messages = {...conversation.messages};

            // get message
            const message = messages[event.id];

            // message doesn't exist: return unaltered state
            if (message === undefined)
            {
                return state;
            }

            // update message ack
            const newMessage = {
                ...message,
                ack: event.ack,
            };

            // update conversation messages
            messages[event.id] = newMessage;

            // update conversation
            byId[event._target] = {
                ...conversation,
                messages,
            };

            // return conversation state
            return {
                ...state,
                byId,
            };
        },


    /**
     * I update chat state on message by original id fetched.
     *
     * :param state: chat state
     * :param event: message by original id fetched event
     *
     * :returns: chat state
     */
    [eventTypes.CONVERSATION_MESSAGE_BY_ORIGINAL_ID_FETCHED]:
        (state: IChatState,
         event: Events.IConversationMessageByOriginalIdFetched): IChatState =>
        {
            // get props from event
            const {
                _target: conversationId,
                data,
                message: messageId,
            } = event;

            // conversation is in byId: return updated message
            if (state.byId[conversationId] !== undefined)
            {
                return {
                    ...state,
                    byId: {
                        ...state.byId,
                        [conversationId]: {
                            ...state.byId[conversationId],
                            messages: {
                                ...state.byId[conversationId].messages,
                                [messageId]: {
                                    ...state.byId[conversationId]
                                        .messages[messageId],
                                    isFetchingReply: false,
                                    repliedMessage: data,
                                    replyFetched: true,
                                },
                            },
                        },
                    },
                };
            }

            // conversation is in finishedById: return updated message
            else if (state.finishedById[conversationId] !== undefined)
            {
                return {
                    ...state,
                    finishedById: {
                        ...state.finishedById,
                        [conversationId]: {
                            ...state.finishedById[conversationId],
                            messages: {
                                ...state.finishedById[conversationId].messages,
                                [messageId]: {
                                    ...state.finishedById[conversationId]
                                        .messages[messageId],
                                    isFetchingReply: false,
                                    repliedMessage: data,
                                    replyFetched: true,
                                },
                            },
                        },
                    },
                };
            }

            // conversation does not exist in conversation aggregate: keep state
            return {...state};
        },


    /**
     * I update chat state on message by original id fetching.
     *
     * :param state: chat state
     * :param event: message by original id fetching event
     *
     * :returns: chat state
     */
    [eventTypes.CONVERSATION_MESSAGE_BY_ORIGINAL_ID_FETCHING]:
        (state: IChatState,
         event: Events.IConversationMessageByOriginalIdFetching): IChatState =>
        {
            // get props from event
            const {
                _target: conversationId,
                message: messageId,
            } = event;

            // conversation is in byId: return updated message
            if (state.byId[conversationId] !== undefined &&
                state.byId[conversationId]?.messages !== null &&
                state.byId[conversationId]?.messages !== undefined &&
                state.byId[conversationId]?.messages[messageId] !== undefined)
            {
                return {
                    ...state,
                    byId: {
                        ...state.byId,
                        [conversationId]: {
                            ...state.byId[conversationId],
                            messages: {
                                ...state.byId[conversationId].messages,
                                [messageId]: {
                                    ...state.byId[conversationId]
                                        .messages[messageId],
                                    isFetchingReply: true,
                                },
                            },
                        },
                    },
                };
            }

            // conversation is in finishedById: return updated message
            else if (state.finishedById[conversationId] !== undefined &&
                     state.finishedById[conversationId]?.messages !== null &&
                     state.finishedById[conversationId]?.
                         messages !== undefined &&
                     state.finishedById[conversationId]?.
                         messages[messageId] !== undefined)
            {
                return {
                    ...state,
                    finishedById: {
                        ...state.finishedById,
                        [conversationId]: {
                            ...state.finishedById[conversationId],
                            messages: {
                                ...state.finishedById[conversationId].messages,
                                [messageId]: {
                                    ...state.finishedById[conversationId]
                                        .messages[messageId],
                                    isFetchingReply: true,
                                },
                            },
                        },
                    },
                };
            }

            // conversation does not exist in conversation aggregate: keep state
            return {...state};
        },


    /**
     * I update chat state on message by original id not fetched.
     *
     * :param state: chat state
     * :param event: message by original id not fetched event
     *
     * :returns: chat state
     */
    [eventTypes.CONVERSATION_MESSAGE_BY_ORIGINAL_ID_NOT_FETCHED]:
        (state: IChatState,
         event: Events.IConversationMessageByOriginalIdNotFetched,
        ): IChatState =>
        {
            // get props from event
            const {
                _target: conversationId,
                message: messageId,
            } = event;

            // conversation is in byId: return updated message
            if (state.byId[conversationId] !== undefined)
            {
                return {
                    ...state,
                    byId: {
                        ...state.byId,
                        [conversationId]: {
                            ...state.byId[conversationId],
                            messages: {
                                ...state.byId[conversationId].messages,
                                [messageId]: {
                                    ...state.byId[conversationId]
                                        .messages[messageId],
                                    isFetchingReply: false,
                                    replyFetched: true,
                                },
                            },
                        },
                    },
                };
            }

            // conversation is in finishedById: return updated message
            else if (state.finishedById[conversationId] !== undefined)
            {
                return {
                    ...state,
                    finishedById: {
                        ...state.finishedById,
                        [conversationId]: {
                            ...state.finishedById[conversationId],
                            messages: {
                                ...state.finishedById[conversationId].messages,
                                [messageId]: {
                                    ...state.finishedById[conversationId]
                                        .messages[messageId],
                                    isFetchingReply: false,
                                    replyFetched: true,
                                },
                            },
                        },
                    },
                };
            }

            // conversation does not exist in conversation aggregate: keep state
            return {...state};
        },


    /**
     * I update chat state on message not sent event.
     *
     * :param state: chat state
     * :param event: message not sent event
     *
     * :returns: chat state
     */
    [eventTypes.CONVERSATION_MESSAGE_NOT_SENT]:
            (state: IChatState,
             event: Events.IConversationMessageNotSent): IChatState =>
            {
                // get conversation id
                const {_target: id} = event;

                // get message props
                const {id: messageId, originalId} = event.message;

                // get conversations
                const byId = {...state.byId};

                // get conversation
                const conversation = byId[id];

                // conversation does not exist: return unaltered state
                if (conversation === undefined)
                {
                    return state;
                }

                // conversation exists: get conversation messages
                const messages = {...conversation.messages};

                // decrease sending messages
                conversation.sendingMessages -= 1;

                // get message
                let message = messages[originalId] ?? messages[messageId];

                // message exists: update message
                if (message !== undefined)
                {
                    // update message ack to not transmitted
                    message = {
                        ...message,
                        ack: AckTypes.NOT_TRANSMITTED,
                        body: event.message.body,
                    };

                    // message is listed by its original id: update it
                    if (messages[originalId] !== undefined)
                    {
                        messages[originalId] = message;
                    }

                    // message is listed by its id: update it
                    else
                    {
                        messages[messageId] = message;
                    }

                    // update conversation messages
                    byId[id] = {...conversation, messages};
                }

                // return updated conversation state
                return {...state, byId};
            },


    /**
         * I update chat state on message original id received event.
         *
         * :param state: chat state
         * :param event: message original id received event
         *
         * :returns: chat state
         */
    [eventTypes.CONVERSATION_MESSAGE_ORIGINAL_ID_RECEIVED]:
            (state: IChatState,
             event: Events.IConversationMessageOriginalIdReceived): IChatState =>
            {
                // get conversations
                const byId = {...state.byId};

                // get conversation
                const conversation = byId[event._target];

                // conversation exists: get conversation messages
                const messages = {...conversation.messages};

                // get message
                const message = messages[event.id];

                // message doesn't exist: return unaltered state
                if (message === undefined)
                {
                    return state;
                }

                // message exists: update message
                const newMessage = {
                    ...message,
                    originalId: event.originalId,
                };

                // update conversation messages
                messages[event.id] = newMessage;

                // update conversation
                byId[event._target] = {
                    ...conversation,
                    messages,
                };


                // return conversation state
                return {
                    ...state,
                    byId,
                };
            },


    /**
      * I update chat state on message received event.
      *
      * :param state: chat state
      * :param event: message received event
      *
      * :returns: chat state
      */
    [eventTypes.CONVERSATION_MESSAGE_RECEIVED]:
        (state: IChatState,
         event: Events.IConversationMessageReceived): IChatState =>
        {
            // get conversation id
            const {_target: conversationId} = event;

            // get message timestamp
            const {timestamp} = event;

            // set message
            const message = {
                ack: event.ack,
                attachments: event.attachments,
                body: event.body,
                caption: event.caption,
                class: event.messageClass,
                conversation: conversationId,
                deleted: event.deleted,
                id: event.id,
                mode: event.mode,
                name: event.name,
                originalId: event.originalId,
                outgoing: null,
                reducedBody: event.reducedBody,
                referral : event.referral,
                reply: event.reply,
                timestamp: timestamp * 1000,
                type: event.messageType,
                uid: event.uid,
            };

            // get conversations
            const byId = {...state.byId};

            // get conversation
            const conversation = byId[conversationId];

            // conversation does not exist: return unaltered state
            if (conversation === undefined)
            {
                return state;
            }

            // conversation exists: get conversation messages
            const messages = {...conversation.messages};

            // initialize last interaction
            let lastInteraction = conversation.lastInteraction;

            // received message is new and has bigger lastInteraction: update it
            if (messages[event.id] === undefined && timestamp > lastInteraction)
            {
                lastInteraction = timestamp;
            }

            // add new message
            messages[event.id] = message;

            // set conversation
            byId[conversationId] = {
                ...conversation,
                lastInteraction,
                messages,
                unansweredMessages: event.unansweredMessages,
            };

            // return chat state
            return {
                ...state,
                byId,
                ongoing: event.ongoing,
            };
        },

    /**
     * I update chat state on message retrying event.
     *
     * :param state: chat state
     * :param event: message retrying event
     *
     * :returns: chat state
     */
    [eventTypes.CONVERSATION_MESSAGE_RETRYING]:
        (state: IChatState,
         event: Events.IConversationMessageRetrying): IChatState =>
        {
            // get conversations
            const byId = {...state.byId};

            // conversation does not exist: return unaltered state
            if (byId[event._target] === undefined)
            {
                return state;
            }

            // get sending messages
            const sendingMessages = byId[event._target].sendingMessages;

            // conversation exists: set conversation
            const conversation = {
                ...state.byId[event._target],
                lastInteraction: Date.now(),
                sendingMessages: sendingMessages + 1,
            };

            // get conversation messages
            const messages = {...conversation.messages};

            // add new message
            messages[event.message.id] = {
                ...event.message,
                ack: AckTypes.TRANSMITTING,
            };

            // update conversation
            conversation.messages = messages;
            byId[event._target] = conversation;

            // return chat state
            return {...state, byId};
        },


    /**
     * I update chat state on message sending event.
     *
     * :param state: chat state
     * :param event: message sending event
     *
     * :returns: chat state
     */
    [eventTypes.CONVERSATION_MESSAGE_SENDING]:
            (state: IChatState,
             event: Events.IConversationMessageSending): IChatState =>
            {
                // get conversation id
                const {_target: id} = event;

                // set message
                const message = {
                    ...event.message,
                    ack: AckTypes.TRANSMITTING,
                };

                // get conversations
                const byId = {...state.byId};

                // get chat by id
                const chat = byId[id];

                // chat does not exist: return unaltered state
                if (chat === undefined)
                {
                    return state;
                }

                // chat exists: get messages
                const messages = {...chat.messages};

                // add new message
                messages[message.originalId] = message;

                // get sending messages
                const sendingMessages = byId[id].sendingMessages;

                // set conversation
                byId[id] = {
                    ...chat,
                    lastInteraction: Date.now() / 1000,
                    messages,
                    sendingMessages: sendingMessages + 1,
                    unansweredMessages: 0,
                };

                // return chat state
                return {
                    ...state,
                    byId,
                    ongoing: event.ongoing,
                };
            },


    /**
     * I update chat state on message sent event.
     *
     * :param state: chat state
     * :param event: message sent event
     *
     * :returns: chat state
     */
    [eventTypes.CONVERSATION_MESSAGE_SENT]:
            (state: IChatState,
             event: Events.IConversationMessageSent): IChatState =>
            {
                // get conversation id
                const {_target: id} = event;

                // get old originalId
                const {oldOriginalId} = event;

                // get message id
                const {id: messageId} = event.message;

                // gety message body
                const {body: messageBody} = event.message;

                // get new original id
                const {originalId: newOriginalId} = event.message;

                // get conversations
                const byId = {...state.byId};

                // get conversation
                const conversation = byId[id];

                // conversation does not exist: return unaltered state
                if (conversation === undefined)
                {
                    return state;
                }

                // conversation exists: get conversation messages
                const messages = {...conversation.messages};

                // decrease sending messages
                conversation.sendingMessages -= 1;

                // get message
                let message = messages[oldOriginalId];

                // message exists: update message
                if (message !== undefined)
                {
                    // remove old message indexed by old original id
                    delete messages[oldOriginalId];

                    // update message ack and set message id and body
                    message = {
                        ...message,
                        ack: AckTypes.TRANSMITTING,
                        body: messageBody,
                        deleted: null,
                        id: messageId,
                        originalId: newOriginalId,
                    };

                    // index message by message id
                    messages[messageId] = message;

                    // update conversation
                    byId[id] = {
                        ...conversation,
                        hasAgentAnswer: true,
                        isAutoAnswered: false,
                        messages,
                    };
                }

                // return conversation state
                return {
                    ...state,
                    byId,
                };
            },


    /**
     * I update chat state on file not uploaded event.
     *
     * :param state: chat state
     * :param event: file not uploaded event
     *
     * :returns: chat state
     */
    [eventTypes.CONVERSATION_FILE_NOT_UPLOADED]:
        (state: IChatState,
         event: Events.IConversationFileNotUploaded): IChatState =>
        {
            // get event data
            const {_target: id} = event;
            const {message: originalId} = event;

            // get conversations
            const {byId} = state;

            // get conversation
            const conversation = byId[id];

            // conversation does not exist: return unaltered state
            if (conversation === undefined)
            {
                return state;
            }

            // conversation exists: get conversation messages
            const messages = {...conversation.messages};

            // get message
            let message = messages[originalId];

            // message exists: update message
            if (message !== undefined)
            {
                // update message upload status
                message = {
                    ...message,
                    upload: UploadStatuses.CANCELED,
                };

                // update conversation messages
                messages[originalId] = message;

                // update conversation
                byId[id] = {
                    ...conversation,
                    messages,
                };
            }

            // return conversation state
            return {
                ...state,
                byId,
            };
        },


    /**
     * I update chat state on file uploaded event.
     *
     * :param state: chat state
     * :param event: file uploaded event
     *
     * :returns: chat state
     */
    [eventTypes.CONVERSATION_FILE_UPLOADED]:
        (state: IChatState,
         event: Events.IConversationFileUploaded): IChatState =>
        {
            // get event data
            const {_target: id} = event;
            const {message: originalId} = event;

            // get conversations
            const {byId} = state;

            // get conversation
            const conversation = byId[id];

            // conversation does not exist: return unaltered state
            if (conversation === undefined)
            {
                return state;
            }

            // conversation exists: get conversation messages
            const messages = {...conversation.messages};

            // get message
            let message = messages[originalId];

            // message exists: update message
            if (message !== undefined)
            {
                // update message upload status
                message = {
                    ...message,
                    upload: UploadStatuses.COMPLETED,
                };

                // update conversation messages
                messages[originalId] = message;

                // update conversation
                byId[id] = {
                    ...conversation,
                    messages,
                };
            }

            // return conversation state
            return {
                ...state,
                byId,
            };
        },


    /**
     * I update chat state on file uploading event.
     *
     * :param state: chat state
     * :param event: file uploading event
     *
     * :returns: chat state
     */
    [eventTypes.CONVERSATION_FILE_UPLOADING]:
        (state: IChatState,
         event: Events.IConversationFileUploading): IChatState =>
        {
            // get event data
            const {_target: id} = event;
            const {message: originalId} = event;
            const {progress} = event;

            // get conversations
            const {byId} = state;

            // get conversation
            const conversation = byId[id];

            // conversation does not exist: return unaltered state
            if (conversation === undefined)
            {
                return state;
            }

            // conversation exists: get conversation messages
            const messages = {...conversation.messages};

            // get message
            let message = messages[originalId];

            // message exists: update message
            if (message !== undefined)
            {
                // update message upload status
                message = {
                    ...message,
                    progress,
                    upload: UploadStatuses.PENDING,
                };

                // update conversation messages
                messages[originalId] = message;

                // update conversation
                byId[id] = {
                    ...conversation,
                    messages,
                };
            }

            // return conversation state
            return {
                ...state,
                byId,
            };
        },


    /**
     * I update chat state on conversation not transferred event.
     *
     * :param state: chat state
     * :param event: conversation transferred event
     *
     * :returns: chat state
     */
    [eventTypes.CONVERSATION_NOT_TRANSFERRED]:
        (state: IChatState,
         event: Events.IConversationNotTransferred): IChatState => ({
            ...state,
            conversation: {
                ...state.conversation,
                hasTransferError: true,
                isTransferring: false,
            },
        }),


    /**
     * I update chat state on online agent added event.
     *
     * :param state: chat state
     * :param event: conversation online agent added event
     *
     * :returns: chat state
     */
    [eventTypes.CONVERSATION_ONLINE_AGENT_ADDED]:
        (state: IChatState,
         event: Events.IConversationOnlineAgentAdded): IChatState =>
        {
            // get event data
            const {
                away,
                connected,
                fullname,
                paused,
                standby,
                uid,
            } = event.agent;

            // return conversation state
            return {
                ...state,
                agents: [
                    ...state.agents,
                    {
                        away,
                        connected,
                        fullname,
                        paused,
                        standby,
                        uid,
                    },
                ],
            };
        },


    /**
     * I update chat state on online agents loaded event.
     *
     * :param state: chat state
     * :param event: conversation online agents loaded event
     *
     * :returns: chat state
     */
    [eventTypes.CONVERSATION_ONLINE_AGENTS_LOADED]:
        (state: IChatState,
         event: Events.IConversationOnlineAgentsLoaded): IChatState => ({
            ...state,
            agents: [],
            conversation: {
                ...state.conversation,
                hasOnlineAgentsLoadError: false,
                isLoadingOnlineAgents: false,
            },
        }),


    /**
     * I update chat state on online agents loading event.
     *
     * :param state: chat state
     * :param event: conversation online agents loading event
     *
     * :returns: chat state
     */
    [eventTypes.CONVERSATION_ONLINE_AGENTS_LOADING]:
        (state: IChatState,
         event: Events.IConversationOnlineAgentsLoading): IChatState => ({
            ...state,
            conversation: {
                ...state.conversation,
                hasOnlineAgentsLoadError: false,
                isLoadingOnlineAgents: true,
            },
        }),


    /**
     * I update chat state on order prioritized event.
     *
     * :param state: chat state
     * :param event: conversation order prioritized event
     *
     * :returns: chat state
     */
    [eventTypes.CONVERSATION_ORDER_PRIORITIZED]:
        (state: IChatState,
         event: Events.IConversationOrderPrioritized): IChatState => ({
            ...state,
            history: event.history,
            ongoing: event.ongoing,
        }),


    /**
     * I update chat state on online agents not loaded event.
     *
     * :param state: chat state
     * :param event: conversation online agents not loaded event
     *
     * :returns: chat state
     */
    [eventTypes.CONVERSATION_ONLINE_AGENTS_NOT_LOADED]:
        (state: IChatState,
         event: Events.IConversationOnlineAgentsNotLoaded): IChatState => ({
            ...state,
            conversation: {
                ...state.conversation,
                hasOnlineAgentsLoadError: true,
                isLoadingOnlineAgents: false,
            },
        }),


    /**
     * I update chat state on real time metrics fetched event.
     *
     * :param state: chat state
     * :param event: real time metrics fetched event
     *
     * :returns: chat state
     */
    [eventTypes.CONVERSATION_REALTIME_METRICS_FETCHED]:
        (state: IChatState,
         event: Events.IConversationRealTimeMetricsFetched): IChatState =>
        {
            // get real time metrics
            const {metrics} = event;

            // return conversation state
            return {
                ...state,
                metrics: {
                    ...state.metrics,
                    realTime: {
                        ...metrics,
                        hasError: false,
                        isFetching: false,
                    },
                },
            };
        },


    /**
     * I update chat state on real time metrics fetching event.
     *
     * :param state: chat state
     * :param event: real time metrics fetching event
     *
     * :returns: chat state
     */
    [eventTypes.CONVERSATION_REALTIME_METRICS_FETCHING]:
        (state: IChatState,
         event: Events.IConversationRealTimeMetricsFetching): IChatState => ({
            ...state,
            metrics: {
                ...state.metrics,
                realTime: {
                    ...state.metrics.realTime,
                    hasError: false,
                    isFetching: true,
                },
            },
        }),


    /**
     * I update chat state on real time metrics not fetched event.
     *
     * :param state: chat state
     * :param event: real time metrics not fetched event
     *
     * :returns: chat state
     */
    [eventTypes.CONVERSATION_REALTIME_METRICS_NOT_FETCHED]:
        (state: IChatState,
         event: Events.IConversationRealTimeMetricsNotFetched): IChatState => ({
            ...state,
            metrics: {
                ...state.metrics,
                realTime: {
                    ...state.metrics.realTime,
                    hasError: true,
                    isFetching: false,
                },
            },
        }),


    /**
     * I update chat state on should exit event.
     *
     * :param state: chat state
     * :param event: conversation should exit event
     *
     * :returns: chat state
     */
    [eventTypes.CONVERSATION_SHOULD_EXIT]:
        (state: IChatState,
         event: Events.IConversationShouldExit): IChatState =>
        {
            // get conversation id
            const {_target: id} = event;

            // get conversations by id
            const byId = state.byId;

            // update target conversation's should exit
            byId[id] = {...byId[id], shouldExit: true};

            // return updated conversation state
            return {...state, byId};
        },


    /**
     * I update chat state on conversation transferred event.
     *
     * :param state: chat state
     * :param event: conversation transferred event
     *
     * :returns: chat state
     */
    [eventTypes.CONVERSATION_TRANSFERRED]:
        (state: IChatState,
         event: Events.IConversationTransferred): IChatState => ({
            ...state,
            conversation: {
                ...state.conversation,
                hasTransferError: false,
                isTransferring: false,
            },
        }),


    /**
     * I update chat state on conversation transferring event.
     *
     * :param state: chat state
     * :param event: conversation transferring event
     *
     * :returns: chat state
     */
    [eventTypes.CONVERSATION_TRANSFERRING]:
        (state: IChatState,
         event: Events.IConversationTransferring): IChatState =>
        {
            // get conversations by id
            const conversations = {...state.byId};

            // update target conversation
            conversations[event._target].shouldExit = true;

            // return updated state
            return {
                ...state,
                byId: conversations,
                conversation: {
                    ...state.conversation,
                    hasTransferError: false,
                    isTransferring: true,
                },
            };
        },


    /**
     * I update chat state on conversation typed text event.
     *
     * :param state: chat state
     * :param event: conversation typed text event
     *
     * :returns: chat state
     */
    [eventTypes.CONVERSATION_TYPED_TEXT]:
    (state: IChatState,
     event: Events.IConversationTypedText): IChatState =>
    {
        // get conversations by id
        const byId = {...state.byId};

        // update typed text in target conversation
        byId[event._target].typedText = event.text;

        // return updated state
        return {
            ...state,
            byId,
        };
    },


    /**
     * I update chat state on conversation transfer reset event.
     *
     * :param state: chat state
     * :param event: conversation transfer reset event
     *
     * :returns: chat state
     */
    [eventTypes.CONVERSATION_TRANSFER_RESET]:
        (state: IChatState,
         event: Events.IConversationTransferReset): IChatState =>
        {
            // return conversation state
            return {
                ...state,
                conversation: {
                    ...state.conversation,
                    hasTransferError: false,
                    isTransferring: false,
                },
            };
        },


    /**
     * I update chat state on unsupported message sent event.
     *
     * :param state: chat state
     * :param event: unsupported message sent event
     *
     * :returns: chat state
     */
    [eventTypes.CONVERSATION_UNSUPPORTED_MESSAGE_SENT]:
        (state: IChatState,
         event: Events.IConversationUnsupportedMessageSent): IChatState =>
        {
            // get conversation id
            const {_target: id} = event;

            // get message originalId
            const {originalId} = event;

            // get conversations
            const byId = {...state.byId};

            // get conversation
            const conversation = byId[id];

            // conversation does not exist: return unaltered state
            if (conversation === undefined)
            {
                return state;
            }

            // conversation exists: get conversation messages
            const messages = {...conversation.messages};

            // get message
            let message = messages[originalId];

            // message exists: update message
            if (message !== undefined)
            {
                // update message ack to unsupported
                message = {
                    ...message,
                    ack: AckTypes.UNSUPPORTED,
                    body: event.body,
                };

                // update conversation messages
                messages[originalId] = message;

                // update conversation
                byId[id] = {
                    ...conversation,
                    messages,
                };
            }

            // return conversation state
            return {
                ...state,
                byId,
            };
        },
};


/**
 * User reducer.
 */
const reducer = Store.createReducer(initialState, actionsMap);


/**
 * EXPORTS
 */
export default reducer;
