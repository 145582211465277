/**
 * IMPORTS
 */
import {gql} from '@apollo/client';


/**
 * EXPORTS
 */

/**
 * I am a query to fetch messages from a conversation.
 */
export const FETCH_CONVERSATION_MESSAGES = gql`
query ConversationMessages ($company: Int!, $id: Int!) {
    conversation (company: $company, id: $id) {
        messages {
            ack
            id
            body
            caption
            deleted
            mode
            originalId
            outgoing
            referral
            reply
            timestamp
            type
        }
    }
}
`;


/**
 * I am a query to fetch a contact timeline
 */
export const FETCH_CONTACT_TIMELINE = gql`
query ContactTimeline ($company: Int!, $account: Int!, $uid: String!) {
    contactTimeline(company: $company, account: $account, uid: $uid) {
        application {
            uid
        }
        agentFinished
        end
        id
        lastInteraction
        start
        subject
        templateRequired
        templateSent
        unansweredMessages
    }
}
`;
